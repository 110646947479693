/*! Copyright 2024 Lane Crawford, All Rights Reserved. */

/**
 *
 * [ module ]
 * misc -> pageTimingFinish
 *
 */

( function( LC ) {

	"use strict";

	LC.misc = LC.misc || {};

	LC.misc.pageTimingFinish = lcmodule( function( exports ) {

		var attempts = 0,
			hasFired = {};

		exports.moduleName = "misc.pageTimingFinish";

		exports.timeEntry = function( timingEntry ) {

			var entries,
				navTiming,
				loadTime = 0;

			if ( !timingEntry ) {
				LC.error( "Cannot time without a timingEntry" );
				return 0;
			}

			if ( window.performance && window.performance.getEntriesByType ) {
				entries = window.performance.getEntriesByType("navigation");
				if ( entries.length > 0 ) {
					navTiming = entries[ 0 ];
					loadTime = navTiming[ timingEntry ] - navTiming.startTime;
				}
			}

			return isNaN( loadTime ) ? 0 : Math.round( loadTime );

		};

		exports.tryTiming = function( timingEntry, eventName ) {

			var timing = exports.timeEntry( timingEntry );

			if ( timing > 0 ) {

				if ( !hasFired[ timingEntry ] ) {
					hasFired[ timingEntry ] = true;
					/* eslint-disable camelcase */
					LC.tracking.event({
						eventName: eventName,
						eventData: { load_time: timing }
					});
					/* eslint-enable camelcase */
				}

			} else if ( attempts < 10 ) {

				attempts++;
				setTimeout( function() {
					exports.tryTiming( timingEntry, eventName );
				}, 5000 );

			}

		};

		exports.init = function() {

			exports.tryTiming( "loadEventEnd", "page_load_fully_loaded" );
			exports.tryTiming( "domContentLoadedEventEnd", "page_load_dom_loaded" );
			exports.tryTiming( "domInteractive", "page_load_dom_interactive" );

			// execute the "finished" event for NewRelic
			// to better track the time that all JS has loaded
			// and the page is actually displaying for the User.
			if ( typeof window.newrelic !== "undefined" ) {

				window.newrelic.finished();

			}

		};

		return exports;

	}({}));

}( window.LC = window.LC || {}, jQuery ));

 //# sourceURL=page.timing.finish.js
